import React from "react";
import { useState } from "react";

import LifeInsurance from "./LifeInsurance";
import MotorInsurance from "./motorInsurance";
import HealthInsurance from "./healthInsurance";
import PropertyInsurance from "./propertyInsurance";

import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const Insurance = (props) => {
    const handleClick = props.handleClick;
    const handleTouch = props.handleTouch;

    const [state, setState] = useState("life");
    return (
        <>
            <div className="card-container insuranceMobile">
                <div className="card-content">
                    <div className="left">
                        <div className="card-heading">
                            <div className="heading1">Insurance</div>
                        </div>
                        <div className="insurance-list">
                            <div className="insurance-ka-kachra">
                                Insurance is a contract, represented by a
                                policy, in which an individual or entity
                                receives financial protection or reimbursement
                                against losses from an insurance company. The
                                company pools clients' risks to make payments
                                more affordable for the insured.
                                <br></br>
                                <br></br>
                                Know more about different Insurances we provide:
                            </div>
                            <div
                                className={
                                    state === "life"
                                        ? "insurance active"
                                        : "insurance"
                                }
                                onClick={() => {
                                    handleTouch(false);
                                    setState("life");
                                }}
                            >
                                Life Insurance
                            </div>
                            <div
                                className={
                                    state === "motor"
                                        ? "insurance active"
                                        : "insurance"
                                }
                                onClick={() => {
                                    handleTouch(false);
                                    setState("motor");
                                }}
                            >
                                Motor Insurance
                            </div>
                            <div
                                className={
                                    state === "health"
                                        ? "insurance active"
                                        : "insurance"
                                }
                                onClick={() => {
                                    handleTouch(false);
                                    setState("health");
                                }}
                            >
                                Health Insurance
                            </div>
                            <div
                                className={
                                    state === "property"
                                        ? "insurance active"
                                        : "insurance"
                                }
                                onClick={() => {
                                    handleTouch(false);
                                    setState("property");
                                }}
                            >
                                Property Insurance
                            </div>
                        </div>
                        <div className="need-help-contact-btn">
                            <Button variant="dark" className="need-help-button">
                                <Link
                                    to="/contact"
                                    style={{
                                        textDecoration: "none",
                                        color: "white",
                                    }}
                                >
                                    Start Now!
                                </Link>
                            </Button>
                        </div>
                    </div>
                    <div
                        className="right"
                        onClick={handleClick}
                        onTouchStart={() => {
                            handleTouch(false);
                        }}
                        onTouchEnd={() => {
                            handleTouch(true);
                        }}
                    >
                        {state === "life" && <LifeInsurance></LifeInsurance>}
                        {state === "motor" && <MotorInsurance></MotorInsurance>}
                        {state === "health" && (
                            <HealthInsurance></HealthInsurance>
                        )}
                        {state === "property" && (
                            <PropertyInsurance></PropertyInsurance>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Insurance;
