import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "../hooks/form";
import { stdcodes } from "../static/countryCodes";
import "../static/css/contact.css";
import logo from "../static/images/logo.svg";
import axios from "axios";

const Contact = (props) => {
  const [form, handleChange] = useForm({
    name: "",
    countryCode: "+91",
    phoneNo: "",
    email: "",
    query: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = (form) => {
    let errors = {};
    if (form.name === "") errors["name"] = "Name is required!";
    if (form.phoneNo === "") errors["phone"] = "Phone Number required!";

    if (Object.keys(errors).length === 0) return null;
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(form);
    setErrors({ ...errors });
    if (!errors) {
      const obj = {
        name: form.name,
        email: form.email,
        phoneNo: form.countryCode + "-" + form.phoneNo,
        query: form.query,
      };
      const { data } = await axios.post(
        "https://dry-falls-16373.herokuapp.com/api/queries",
        obj
      );

      alert(data);
    }
  };

  return (
    <div className="contact-container">
      <div className="contact-title">Contact Us</div>
      <div className="contact-content">
        <div className="contact-info">
          <h1>24 Karat Investemnt & Insurance Advisors</h1>
          <br></br>
          <img className="contact-logo" src={logo} alt=""></img>
          <div className="contact-details">
            <span className="material-icons">place</span>18/678, Chopasani
            Housing Board, Jodhpur (Raj.)-342001
            <br></br>
            <div className="contact-phone">
              <b>
                <span className="material-icons">phone</span>
                +91-7737706877 +91-9828296877
              </b>
              <br></br>
            </div>
            <div className="contact-email">
              <b>
                <span className="material-icons">email</span>
                24karatadvisors@gmail.com
              </b>
            </div>
          </div>
        </div>
        <div className="vl"></div>
        <hr className="hl" />
        <div className="query-container">
          <h1>Ask a Query!</h1>
          <br></br>
          <div className="query-form">
            <form onSubmit={handleSubmit} className="form-container">
              <div className="query-name">
                <div className="query-name-text">
                  <b>Name</b>
                </div>
                <input
                  name="name"
                  value={form.value}
                  className="form-name"
                  placeholder="Full Name"
                  onChange={handleChange}
                ></input>
              </div>
              {errors["name"] ? (
                <div className="alert alert-danger box-error">
                  {errors["name"]}
                </div>
              ) : (
                <br></br>
              )}
              <div className="query-phone">
                <div className="query-phone-text">
                  <b>Phone</b>
                </div>
                <div className="query-phone-input">
                  <select
                    name="countryCode"
                    className="form-countrycode"
                    value={form.countryCode}
                    onChange={handleChange}
                  >
                    {stdcodes.map((std) => (
                      <option key={std.name} value={std.dial_code}>
                        {std.name + " (" + std.code + ")"}
                      </option>
                    ))}
                  </select>
                  <input
                    name="phoneNo"
                    className="form-phone"
                    value={form.phoneNo}
                    placeholder="Phone Number"
                    onChange={handleChange}
                  ></input>
                </div>
              </div>
              {errors["phone"] ? (
                <div className="alert alert-danger box-error">
                  {errors["phone"]}
                </div>
              ) : (
                <br></br>
              )}
              <div className="query-email">
                <div className="query-email-text">
                  <b>Email</b>
                </div>
                <input
                  name="email"
                  className="form-email"
                  value={form.email}
                  placeholder="Email Address"
                  onChange={handleChange}
                ></input>
              </div>
              <br></br>
              <div className="query-ques">
                <div className="query-ques-text">
                  <b>Ask a Question!</b>
                </div>
                <textarea
                  name="query"
                  className="form-query"
                  value={form.query}
                  placeholder="Type your query here..."
                  onChange={handleChange}
                ></textarea>
              </div>
              <br></br>
              <div className="query-submit-button-div">
                <Button
                  type="submit"
                  variant="primary"
                  className="query-submit-button-btn"
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
