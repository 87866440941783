import React, { useEffect, useState } from "react";
import { useForm } from "../../hooks/form";
import { values } from "../../static/SIPvalues";

import "../../static/css/sip.css";
import SipChart from "./sipChart";

import rupee from "../../static/images/rupee-indian.svg";
import percent from "../../static/images/percentage.svg";

const SIPcalculator = (props) => {
    const [form, handleChange] = useForm({
        sipAmount: "",
        duration: "",
        expectedAnnualRate: "",
        investmentFrequency: "12",
    });

    const formChange = (e) => {
        e.target.value = e.target.value
            ? parseInt(e.target.value.replace(/,/g, "")).toLocaleString("en-IN")
            : "";
        handleChange(e);
    };

    const [output, setOutput] = useState({
        investedAmount: "",
        expectedValue: "",
        expectedReturns: "",
    });

    const computeOutput = (form) => {
        const P = parseInt(form.sipAmount.replace(/,/g, ""));
        const duration = parseInt(form.duration);
        const expectedAnnualRate = parseInt(form.expectedAnnualRate);
        const investmentFrequency = parseInt(form.investmentFrequency);

        const n = duration * investmentFrequency;
        const i = expectedAnnualRate / (investmentFrequency * 100);

        const investedAmount = n * P;
        const expectedValue = P * (((1 + i) ** n - 1) / i) * (1 + i);

        return {
            investedAmount: Math.round(investedAmount).toLocaleString("en-IN"),
            expectedValue: Math.round(expectedValue).toLocaleString("en-IN"),
            expectedReturns: Math.round(
                expectedValue - investedAmount
            ).toLocaleString("en-IN"),
        };
    };

    useEffect(() => {
        const result = computeOutput(form);
        setOutput({ ...result });
    }, [form]);

    return (
        <div className="all-cal">
            <div className="inputs">
                <div className="input">
                    <label>SIP Amount</label>
                    <div className="input-box">
                        <input
                            name="sipAmount"
                            value={form.sipAmount}
                            onChange={formChange}
                        ></input>
                        <img src={rupee} height="20px" alt="" />
                    </div>
                </div>

                <div className="input">
                    <label>Investment Duration</label>
                    <div className="input-box">
                        <input
                            name="duration"
                            value={form.duration}
                            onChange={handleChange}
                        ></input>
                        <span>&nbsp;Yrs.</span>
                    </div>
                </div>
                <div className="input">
                    <label>Expected Return Rate</label>
                    <div className="input-box">
                        <input
                            name="expectedAnnualRate"
                            value={form.expectedAnnualRate}
                            onChange={handleChange}
                        ></input>
                        <img src={percent} height="20px" alt="" />
                    </div>
                </div>
                <div className="input">
                    <label>Installment Type</label>
                    <select
                        name="investmentFrequency"
                        value={form.investmentFrequency}
                        onChange={handleChange}
                    >
                        {values.map((val) => (
                            <option value={val.freq}>{val.name}</option>
                        ))}
                    </select>
                </div>
                <div className="output">
                    <label>Expected Value</label>
                    <div className="output-box">
                        {output.expectedValue != "NaN" ? (
                            <span>
                                {output.expectedValue.toLocaleString("en-IN")}
                            </span>
                        ) : (
                            <span></span>
                        )}
                        <img src={rupee} alt="" />
                    </div>
                </div>
            </div>
            <div className="description">
                <SipChart
                    investedAmount={output.investedAmount.replace(/,/g, "")}
                    expectedValue={output.expectedValue.replace(/,/g, "")}
                ></SipChart>
            </div>
        </div>
    );
};

export default SIPcalculator;
