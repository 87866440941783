import React, { Fragment } from "react";
import backImg from "../../static/images/services vectors/property.jpg";

import "../../static/css/services/property.css";

const PropertyInsurance = () => {
  return (
    <Fragment>
      <div
        className="background"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${backImg})`,
        }}
      ></div>
      <div className="para property">
        <div className="para1">
          Any building or immovable structure can be insured through property
          insurance plans. This can be either your residence or commercial
          space. If any damage befalls such a property, you can claim financial
          assistance from the insurance provider. Keep in mind that such a plan
          also financially safeguards the content inside the property.
        </div>
        <div className="para2">
          Here are some types of property insurance policies available in India:
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <b>Home Insurance -</b> With such a policy, you remain free from
              all financial liabilities that may arise from damage to your home
              or contents inside due to fires, burglaries, storms, earthquakes,
              explosions and other events.
            </li>
            <li>
              <b>Shop Insurance -</b> If you own a shop, which acts as a source
              of income for you, it is integral to protect yourself from
              financial liability arising from the same. Whether the liability
              occurs due to natural calamities or due to accidents, with these
              plans, you can immediately undertake repairs to the shop.
            </li>
            <li>
              <b>Office Insurance -</b> Another type of property insurance
              policy, office insurance ensures that the office building and all
              the equipment inside are significantly protected in the event of
              unforeseen events. Generally, office spaces include expensive
              equipment, such as computers, servers and much more. Thus,
              availing these plans is essential.
            </li>
            <li>
              <b>Building Insurance -</b> If you own a complete building, opting
              for home insurance may not be sufficient. Instead, you can
              purchase building insurance to cover the entire premises.
            </li>
          </ul>
          <h3>Benefits of Property Insurance</h3>
          <br></br>
          If you still think that property cover is not one of the types of
          insurance plans you need to avail, take a look at some of the
          advantages from the same.
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <b>Protection against Fires -</b> While the insurance policy
              cannot prevent fires, it can prevent financial liabilities from
              such an event.
            </li>
            <li>
              <b>Burglaries -</b> If your property exists in an area prone to
              theft and burglaries, such a policy is vital to ensure financial
              security.
            </li>
            <li>
              <b>Floods -</b> In certain parts of India, floods are common.
              These floods can ravage your property leading to substantial
              losses. Property insurance also protects against such events.
            </li>
            <li>
              <b>Natural Calamities -</b> The plan also offers financial aid
              against damage arising from earthquakes, storms and more.
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default PropertyInsurance;
