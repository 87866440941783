import React from "react";
import "../static/css/about.css";
import photo from "../static/images/contact.png";

const About = () => {
  return (
    <div className="about-wrapper">
      <div className="about-us-container">
        <div className="about-title">About Us</div>
        <div className="about-upper-content">
          <div className="about-upper-content-heading">
            We are here for you!
          </div>
          <h4>
            24 KARAT ADVISORS LLP is an LLP registered under Companies Act,
            2013.
          </h4>
          <br></br>
          Dedicated to providing the right financial advice for all your
          investments & insurance concerns. We’ve got everything covered with a
          personalised approach for each of our clients & risk management is a
          plus!
          <br />
          <br />
          Nothing is more important to us than your satisfaction. Your
          investment options don’t need to be confusing. We are all ears to your
          goals and are here to help you understand what's best for you.
          <br></br>
          <br></br>
          Everything we do at 24 Karat is guided by our core values:
          <b>
            &nbsp;clients first, exceptional ideas & while we do the right
            thing, we’re always committed to diversity.
          </b>
          <br></br>
        </div>
        <div className="about-lower-content">
          <div className="about-lower-content-heading">What sets us apart</div>
          <h1>OUR MISSION</h1>
          <p>
            To make investment advice accessible to everyone. With strong
            investment principles and unique strategies, 24 karat aims to
            deliver the right financial goals for all our clients.
          </p>
          <br></br>
          <h1>OUR VISION</h1>
          <p>
            To be a respected leader globally & the most trusted partner for all
            our clients while we maintain long-lasting partnerships.
          </p>
          <br></br>
          <h1>OUR PROMISE</h1>
          <p>
            We promise to manage your wealth and help meet your personal
            financial goals while keeping you abreast of the latest trends and
            developments by offering keen insights on investing capabilities
            that shape today's markets.
          </p>
          <br></br>
          <h1>OUR VIBE</h1>
          <p>
            We believe our culture of access and inclusion will shape our
            future, while helping to strengthen our business and bring value to
            our clients.
          </p>
          <br></br>
          <h1>LEADERSHIP</h1>
          <img className="photo" src={photo} alt=""></img>
          <p>
            24 KARAT ADVISORS LLP is Mahak Gehani’s venture which takes off in
            2021. A technical analyst with 7+ years experience in the stock
            market. Masters in economics, diploma in international business &
            finance adds to his value-based approach. Mahak is also a qualified
            Company Secretary which compliments his strong fundamentals about
            company foundations.
            <br />
            <br />
            Registered as a mutual fund distributor by AFMI and an insurance
            agent. We are also tied up with most of the reliable organisations
            in India which helps us cover all the investment and insurance
            concerns of our clients. Mahak’s aims to not only survive but to
            thrive with cutting-edge investment insights & diversified product
            portfolio, which are as sane and pure as 24 karat gold.
          </p>
        </div>
        <div className="about-quote">
          <div className="about-quote-data">
            <p>
              <i>
                <span class="material-icons quote-open">format_quote</span>
                ‘’Someone’s sitting in the shade today because someone planted a
                tree a long time ago’’
                <br />
                As a tree takes time to grow, and so will most smart
                investments. Start early, be patient, and let your money grow.
                <span class="material-icons">format_quote</span>
                <br />
                <strong>- Warren Buffet</strong>
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
