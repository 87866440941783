import React, { useEffect, useState } from "react";
import { useForm } from "../../hooks/form";

import "../../static/css/cagr.css";

import rupee from "../../static/images/rupee-indian.svg";
import percent from "../../static/images/percentage.svg";

const CAGRCal = (props) => {
  const [form, handleChange] = useForm({
    initialInvestment: "",
    finalInvestment: "",
    duration: "",
    returnType: "",
  });

  const formChange = (e) => {
    e.target.value = e.target.value
      ? parseInt(e.target.value.replace(/,/g, "")).toLocaleString("en-IN")
      : "";
    handleChange(e);
  };

  const [output, setOutput] = useState({ CAGR: 0 });

  const computeResult = (form) => {
    const initialInvestment = parseInt(
      form.initialInvestment.replace(/,/g, "")
    );
    const finalInvestment = parseInt(form.finalInvestment.replace(/,/g, ""));
    const duration = parseInt(form.duration);

    const CAGR = (finalInvestment / initialInvestment) ** (1 / duration) - 1;
    return { CAGR: (CAGR * 100).toFixed(2) };
  };

  useEffect(() => {
    const result = computeResult(form);
    setOutput(result);
  }, [form]);

  return (
    <div className="cagr-cal">
      <div className="inputs">
        <div className="input input1">
          <label>Initial Investment</label>
          <div className="input-box">
            <input
              value={form.initialInvestment}
              name="initialInvestment"
              onChange={formChange}
            ></input>
            <img src={rupee} height="20px" alt="" />
          </div>
        </div>
        <div className="input input1">
          <label>Final Investment</label>
          <div className="input-box">
            <input
              value={form.finalInvestment}
              name="finalInvestment"
              onChange={formChange}
            ></input>
            <img src={rupee} height="20px" alt="" />
          </div>
        </div>
        <div className="input">
          <label>Investment Duration</label>
          <div className="input-box">
            <input
              value={form.duration}
              name="duration"
              onChange={handleChange}
            ></input>
            <span>&nbsp;Yrs.</span>
          </div>
        </div>
        <div className="output output1">
          <label>CAGR</label>
          <div className="output-box">
            {!isNaN(output.CAGR) ? <span>{output.CAGR}</span> : <span></span>}
            <img src={percent} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CAGRCal;
