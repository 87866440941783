import React from "react";
import { Bar } from "react-chartjs-2";

import "../../static/css/sipchart.css";

const SipChart = (props) => {
    const investedAmount = props.investedAmount;
    const expectedValue = props.expectedValue;

    const data = {
        labels: ["Invested Amount", "Expected Value"],
        datasets: [
            {
                data: [investedAmount, expectedValue],
                backgroundColor: ["#ffc93c", "#1a508b"],
            },
        ],
    };

    const options = {
        legend: false,
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        fontSize: 16,
                        fontColor: "#000",
                    },
                },
            ],
            xAxes: [
                {
                    maxBarThickness: 100,
                    ticks: {
                        fontSize: 16,
                        fontColor: "#000",
                    },
                },
            ],
        },
    };

    return (
        <div className="sipchart-container">
            <Bar data={data} options={options}></Bar>
        </div>
    );
};

export default SipChart;
