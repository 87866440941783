import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import "../static/css/home.css";
import PartnerCarousel from "./carousel";

import logo from "../static/images/logo.svg";
import getStarted0 from "../static/images/getStarted/SIP.svg";
import getStarted1 from "../static/images/getStarted/wealth.svg";
import getStarted2 from "../static/images/getStarted/save.svg";
import getStarted3 from "../static/images/getStarted/principle.svg";
import getStarted4 from "../static/images/getStarted/health.svg";
import getStarted5 from "../static/images/getStarted/tax.svg";
import getStarted6 from "../static/images/getStarted/emergency.svg";
import getStarted7 from "../static/images/getStarted/secure.svg";
import getStarted8 from "../static/images/getStarted/goals.svg";
import help from "../static/images/help.jpg";
class Home extends Component {
  state = {};
  render() {
    return (
      <div className="home-wrapper">
        <div className="home-questions-wrapper">
          <div className="home-questions-card-container">
            <div className="home-questions-card-container-title">
              <h2>Do you have questions like?</h2>
            </div>

            <div className="home-questions-card" id="q0">
              <div className="home-questions-card-text">1. What is SIP?</div>
            </div>
            <div className="home-questions-card" id="q1">
              <div className="home-questions-card-text">
                2. Can I invest if I have no idea about stock market?
              </div>
            </div>
            <div className="home-questions-card" id="q2">
              <div className="home-questions-card-text">
                3. What is the difference between equity and debt mutual fund?
              </div>
            </div>
            <div className="home-questions-card" id="q3">
              <div className="home-questions-card-text">
                4. Can I start investing with 500 Rs.?
              </div>
            </div>
            <div className="home-questions-card" id="q4">
              <div className="home-questions-card-text">
                5. Can I create 1 Crore wealth with small investment every
                month?
              </div>
            </div>
            <div className="home-questions-card" id="q5">
              <div className="home-questions-card-text">
                6. What is term insurance?
              </div>
            </div>
            <div className="home-questions-card" id="q6">
              <div className="home-questions-card-text">
                7. How much does life insurance cost?
              </div>
            </div>
            <div className="home-questions-card" id="q7">
              <div className="home-questions-card-text">
                8. How much life insurance coverage should I need?
              </div>
            </div>
          </div>
          <div className="home-answer">
            <img alt="" className="home-answer-logo" src={logo} />
            <div className="home-answer-text">
              <h2>24 Karat Investments has all the answers!</h2>
            </div>
          </div>
        </div>
        <div className="get-started-wrapper">
          <div className="get-started-heading">GET STARTED</div>
          <div className="get-started-subheading">
            Begin your investment journey
          </div>
          <div className="get-started-card-groups">
            <div className="get-started-card-group">
              <div className="get-started-card">
                <div className="get-started-card-icon">
                  <img
                    alt=""
                    src={getStarted0}
                    width="40"
                    height="40"
                    className="d-inline-block align-top"
                  />
                </div>
                <div className="get-started-card-text">
                  <div className="get-started-card-title">Start a SIP</div>
                  <div className="get-started-card-subtitle">
                    Best equity and debt mutual funds
                  </div>
                </div>
              </div>
              <div className="get-started-card">
                <div className="get-started-card-icon">
                  <img
                    alt=""
                    src={getStarted1}
                    width="40"
                    height="40"
                    className="d-inline-block align-top"
                  />
                </div>
                <div className="get-started-card-text">
                  <div className="get-started-card-title">Build Wealth</div>
                  <div className="get-started-card-subtitle">
                    Top equity mutual funds for long-term goals
                  </div>
                </div>
              </div>
              <div className="get-started-card">
                <div className="get-started-card-icon">
                  <img
                    alt=""
                    src={getStarted2}
                    width="40"
                    height="40"
                    className="d-inline-block align-top"
                  />
                </div>
                <div className="get-started-card-text">
                  <div className="get-started-card-title">Save Smart</div>
                  <div className="get-started-card-subtitle">
                    Beat FD returns with the best debt mutual funds
                  </div>
                </div>
              </div>
            </div>
            <div className="get-started-card-group">
              <div className="get-started-card">
                <div className="get-started-card-icon">
                  <img
                    alt=""
                    src={getStarted3}
                    width="40"
                    height="40"
                    className="d-inline-block align-top"
                  />
                </div>
                <div className="get-started-card-text">
                  <div className="get-started-card-title">
                    Principle Protection and Growth
                  </div>
                  <div className="get-started-card-subtitle">
                    Protect your principal and maximise your growth
                  </div>
                </div>
              </div>
              <div className="get-started-card">
                <div className="get-started-card-icon">
                  <img
                    alt=""
                    src={getStarted4}
                    width="40"
                    height="40"
                    className="d-inline-block align-top"
                  />
                </div>
                <div className="get-started-card-text">
                  <div className="get-started-card-title">
                    Take Care of Health
                  </div>
                  <div className="get-started-card-subtitle">
                    Best mediclaim to secure you and your family
                  </div>
                </div>
              </div>
              <div className="get-started-card">
                <div className="get-started-card-icon">
                  <img
                    alt=""
                    src={getStarted5}
                    width="40"
                    height="40"
                    className="d-inline-block align-top"
                  />
                </div>
                <div className="get-started-card-text">
                  <div className="get-started-card-title">Save Tax</div>
                  <div className="get-started-card-subtitle">
                    Top ELSS funds to save tax the smart way
                  </div>
                </div>
              </div>
            </div>
            <div className="get-started-card-group">
              <div className="get-started-card">
                <div className="get-started-card-icon">
                  <img
                    alt=""
                    src={getStarted6}
                    width="40"
                    height="40"
                    className="d-inline-block align-top"
                  />
                </div>
                <div className="get-started-card-text">
                  <div className="get-started-card-title">
                    Be Emergency-ready
                  </div>
                  <div className="get-started-card-subtitle">
                    Top liquid funds for life's surprise expenses
                  </div>
                </div>
              </div>
              <div className="get-started-card">
                <div className="get-started-card-icon">
                  <img
                    alt=""
                    src={getStarted7}
                    width="40"
                    height="40"
                    className="d-inline-block align-top"
                  />
                </div>
                <div className="get-started-card-text">
                  <div className="get-started-card-title">
                    Secure Your Valuables
                  </div>
                  <div className="get-started-card-subtitle">
                    Best insurance plans for property, motor and other valuables
                  </div>
                </div>
              </div>
              <div className="get-started-card">
                <div className="get-started-card-icon">
                  <img
                    alt=""
                    src={getStarted8}
                    width="40"
                    height="40"
                    className="d-inline-block align-top"
                  />
                </div>
                <div className="get-started-card-text">
                  <div className="get-started-card-title">
                    Achieve Life Goals
                  </div>
                  <div className="get-started-card-subtitle">
                    Customised plans for retirement, child education & more
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="help-wrapper">
          <div className="help-heading">
            <h1>We're here to help!</h1>
          </div>
          <div className="help-card">
            <div className="help-card-image">
              <div
                className="help-card-image-img"
                style={{
                  backgroundImage: `url(${help})`,
                }}
              ></div>
            </div>
            <div className="help-card-text">
              <ul className="help-card-text-list">
                <li>
                  <span className="material-icons">check</span>
                  Cutting-edge investments insights
                </li>
                <li>
                  <span className="material-icons">check</span>
                  Paperless process
                </li>
                <li>
                  <span className="material-icons">check</span>
                  Personalised approach tailored to your requirements
                </li>
                <li>
                  <span className="material-icons">check</span>
                  Right insurance policy that covers you and your family
                </li>
                <li>
                  <span className="material-icons">check</span>
                  Diversified product portfolio
                </li>
                <Link to="/contact" className="help-card-text-list-link">
                  Help me Understand
                </Link>
              </ul>
            </div>
          </div>
        </div>
        <div className="need-help-wrapper">
          <div className="need-help">
            <div className="need-help-box-phone-icon">
              <span className="material-icons">local_phone</span>
            </div>
            <div className="need-help-box-text">
              <div className="need-help-box-heading">
                Need help with investing?
              </div>
              <div className="need-help-box-subheading">
                Call us to know more!
              </div>
            </div>
            <div className="need-help-contact-btn">
              <Button variant="dark" className="need-help-button">
                <Link
                  to="/contact"
                  style={{
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  Call us!
                </Link>
              </Button>
            </div>
          </div>
        </div>
        <div className="partners-wrapper">
          <div className="partners-heading">
            <h1>Our Partners</h1>
          </div>
          <div className="partners-carousel">
            <PartnerCarousel></PartnerCarousel>
          </div>
        </div>
        <div className="footer">
          <div className="footer-info">
            <div className="footer-info-heading">
              &copy; 24 Karat Investement & Insurance Advisors
            </div>
            <div className="footer-info-text">
              <div className="footer-info-text-address">
                <span className="material-icons">place</span>
                18/678, Chopasani Housing Board, Jodhpur (Raj.)-342001
              </div>
              <br />
              <div className="footer-info-text-phone">
                <span className="material-icons">phone</span>
                +91-7737706877 +91-9828296877
              </div>
              <br />
              <div className="footer-info-text-email">
                <span className="material-icons">email</span>
                24karatadvisors@gmail.com
              </div>
              <br />
            </div>
          </div>
          <div className="footer-disclaimer">
            Mutual Fund investments are subject to market risks. Please read all
            scheme related documents carefully before investing. Past
            performance is not an indicator of future returns.
          </div>
          <div className="footer-links">
            <Link to="/privacy" className="footer-privacy-link">
              Privacy Policy
            </Link>
            <Link to="/termsofuse" className="footer-terms-link">
              Terms of Use
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
