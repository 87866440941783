import React, { Component } from "react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import carousel0 from "../static/images/carousel/axis.jpg";
import carousel1 from "../static/images/carousel/bajaj.jpg";
import carousel2 from "../static/images/carousel/franklin.jpg";
import carousel3 from "../static/images/carousel/hdfc.jpg";
import carousel4 from "../static/images/carousel/icici.jpg";
import carousel5 from "../static/images/carousel/kotak.jpg";
import carousel6 from "../static/images/carousel/lombard.jpg";
import carousel7 from "../static/images/carousel/l&t.jpg";
import carousel8 from "../static/images/carousel/maxbupa.jpg";
import carousel9 from "../static/images/carousel/mirae.jpg";
import carousel10 from "../static/images/carousel/motilal.jpg";
import carousel11 from "../static/images/carousel/ppfas.jpg";
import carousel12 from "../static/images/carousel/tata.jpg";

import "../static/css/carousel.css";
class PartnerCarousel extends Component {
  render() {
    return (
      <div className="partners-slider">
        <Carousel
          dots={false}
          autoPlay={2000}
          infinite={true}
          itemWidth={190}
          draggable={false}
          breakpoints={{
            1366: {
              itemWidth: 85,
            },
          }}
        >
          <img src={carousel0} alt="" />
          <img src={carousel1} alt="" />
          <img src={carousel2} alt="" />
          <img src={carousel3} alt="" />
          <img src={carousel4} alt="" />
          <img src={carousel5} alt="" />
          <img src={carousel6} alt="" />
          <img src={carousel7} alt="" />
          <img src={carousel8} alt="" />
          <img src={carousel9} alt="" />
          <img src={carousel10} alt="" />
          <img src={carousel11} alt="" />
          <img src={carousel12} alt="" />
        </Carousel>
      </div>
    );
  }
}

export default PartnerCarousel;
