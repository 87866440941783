import React, { useEffect, useState } from "react";
import { useForm } from "../../hooks/form";
import "../../static/css/futureValue.css";
import FutureValueChart from "./futureValueChart";

import rupee from "../../static/images/rupee-indian.svg";
import percent from "../../static/images/percentage.svg";

const FutureValueCal = () => {
    const [form, handleChange] = useForm({
        lumpSum: "",
        sipAmount: "",
        period: "",
        expectedReturn: "",
    });

    const formChange = (e) => {
        e.target.value = e.target.value
            ? parseInt(e.target.value.replace(/,/g, "")).toLocaleString("en-IN")
            : "";
        handleChange(e);
    };

    const [output, setOutput] = useState({
        oneTimeReturn: "",
        SIPInvestmentReturn: "",
        oneTimeInvested: "",
        investedAmount: "",
        totalReturn: "",
    });

    useEffect(() => {
        const result = computeResult(form);
        setOutput(result);
    }, [form]);

    const computeResult = (form) => {
        const sip = parseInt(form.sipAmount.replace(/,/g, ""));
        const oneTime = parseInt(form.lumpSum.replace(/,/g, ""));
        const duration = parseInt(form.period);
        const expectedReturn = parseInt(form.expectedReturn);

        const oneTimeReturn = oneTime * (1 + expectedReturn / 100) ** duration;

        const n = duration * 12;
        const i = expectedReturn / (12 * 100);

        const investedAmount = n * sip;
        const expectedValue = sip * (((1 + i) ** n - 1) / i) * (1 + i);
        const totalReturn = oneTimeReturn + expectedValue;

        console.log(Number(oneTimeReturn.toFixed(2)).toLocaleString("en-IN"));

        return {
            oneTimeReturn: Math.round(oneTimeReturn).toLocaleString("en-IN"),
            SIPInvestmentReturn: Math.round(expectedValue).toLocaleString(
                "en-IN"
            ),
            oneTimeInvested: Math.round(oneTime).toLocaleString("en-IN"),
            investedAmount: Math.round(investedAmount).toLocaleString("en-IN"),
            totalReturn: Math.round(totalReturn).toLocaleString("en-IN"),
        };
    };

    return (
        <div className="all-cal">
            <div className="inputs">
                <div className="input">
                    <label>Lumpsum / One Time Investment</label>
                    <div className="input-box">
                        <input
                            value={form.lumpSum}
                            name="lumpSum"
                            onChange={formChange}
                        ></input>
                        <img src={rupee} height="20px" alt=""></img>
                    </div>
                </div>
                <div className="input">
                    <label>SIP / Monthly Investment</label>
                    <div className="input-box">
                        <input
                            value={form.sipAmount}
                            name="sipAmount"
                            onChange={formChange}
                        ></input>
                        <img src={rupee} height="20px" alt=""></img>
                    </div>
                </div>
                <div className="input">
                    <label>Investment Duration</label>
                    <div className="input-box">
                        <input
                            value={form.period}
                            name="period"
                            onChange={handleChange}
                        ></input>
                        <span>&nbsp;Yrs.</span>
                    </div>
                </div>
                <div className="input">
                    <label>Expected Return (Annualised)</label>
                    <div className="input-box">
                        <input
                            value={form.expectedReturn}
                            name="expectedReturn"
                            onChange={handleChange}
                        ></input>
                        <img src={percent} height="20px" alt=""></img>
                    </div>
                </div>
                <div className="output">
                    <label>One Time Return</label>
                    <div className="output-box">
                        {output.oneTimeReturn != "NaN" ? (
                            <span>{output.oneTimeReturn}</span>
                        ) : (
                            <span></span>
                        )}
                        <img src={rupee} alt="" />
                    </div>
                </div>
                <div className="output">
                    <label>SIP Investment Return</label>
                    <div className="output-box">
                        {output.SIPInvestmentReturn != "NaN" ? (
                            <span>{output.SIPInvestmentReturn}</span>
                        ) : (
                            <span></span>
                        )}
                        <img src={rupee} alt="" />
                    </div>
                </div>
                <div className="output">
                    <label>Total Return</label>
                    <div className="output-box">
                        {output.totalReturn != "NaN" ? (
                            <span>{output.totalReturn}</span>
                        ) : (
                            <span></span>
                        )}
                        <img src={rupee} alt="" />
                    </div>
                </div>
            </div>
            <div className="description">
                <FutureValueChart
                    oneTimeReturn={output.oneTimeReturn.replace(/,/g, "")}
                    oneTimeInvested={output.oneTimeInvested.replace(/,/g, "")}
                    SIPInvestmentReturn={output.SIPInvestmentReturn.replace(
                        /,/g,
                        ""
                    )}
                    investedAmount={output.investedAmount.replace(/,/g, "")}
                ></FutureValueChart>
            </div>
        </div>
    );
};

export default FutureValueCal;
