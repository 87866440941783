import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import "../static/css/navbar.css";
import logo from "../static/images/logo.svg";

const Navigationbar = (props) => {
  const { location } = props;
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="white"
      variant="light"
      className="shadow"
    >
      <Navbar.Brand href="/" className="pr-2 py-0">
        <img
          alt="24 Karat Investments"
          src={logo}
          width="40"
          height="40"
          className="d-inline-block align-top"
        />
        <div className="navbar-brand-text">24 Karat Investments</div>
      </Navbar.Brand>
      <Navbar.Text pullLeft>A pure advice</Navbar.Text>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end"
      >
        <Nav className="px-5 mx-5" activeKey={location.pathname}>
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/services">Services</Nav.Link>
          <Nav.Link href="/calculators">Calculators</Nav.Link>
          <Nav.Link href="/about">About Us</Nav.Link>
          <Nav.Link href="/contact">Contact Us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigationbar;
