import React from "react";
import { Bar } from "react-chartjs-2";

const GoalSipChart = (props) => {
  const totalInvestment = props.totalInvestment;
  const goalAmount = props.goalAmount;

  const data = {
    labels: ["Invested Amount", "Goal Amount"],
    datasets: [
      {
        data: [totalInvestment, goalAmount],
        backgroundColor: ["#ffc93c", "#1a508b"],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: 16,
            fontColor: "#000",
          },
        },
      ],
      xAxes: [
        {
          maxBarThickness: 100,
          ticks: {
            fontSize: 16,
            fontColor: "#000",
          },
        },
      ],
    },
  };

  return (
    <div className="goalSip-chart-container">
      <Bar data={data} options={options}></Bar>
    </div>
  );
};

export default GoalSipChart;
