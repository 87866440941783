import React, { useEffect, useState } from "react";
import { useForm } from "../../hooks/form";

import "../../static/css/infaltion.css";
import InflationChart from "./inflationChart";

import rupee from "../../static/images/rupee-indian.svg";
import percent from "../../static/images/percentage.svg";

const InflationCalculator = () => {
    const [form, handleChange] = useForm({
        currentExpenses: "",
        annualInflationRate: "",
        timePeriod: "",
    });

    const formChange = (e) => {
        e.target.value = e.target.value
            ? parseInt(e.target.value.replace(/,/g, "")).toLocaleString("en-IN")
            : "";
        handleChange(e);
    };

    const [output, setOutput] = useState({ inflatedValue: "" });

    const computeResult = (form) => {
        const currentExpenses = parseInt(
            form.currentExpenses.replace(/,/g, "")
        );
        const annualInflationRate = parseInt(form.annualInflationRate);
        const timePeriod = parseInt(form.timePeriod);

        const inflatedValue =
            currentExpenses * (1 + annualInflationRate / 100) ** timePeriod;
        return {
            inflatedValue: Math.round(inflatedValue).toLocaleString("en-IN"),
        };
    };

    useEffect(() => {
        const result = computeResult(form);
        setOutput({ ...result });
    }, [form]);

    return (
        <div className="all-cal">
            <div className="inputs">
                <div className="input input1">
                    <label>Value of Current Expenses</label>
                    <div className="input-box">
                        <input
                            value={form.currentExpenses}
                            name="currentExpenses"
                            onChange={formChange}
                        ></input>
                        <img alt="" src={rupee} height="20px" />
                    </div>
                </div>
                <div className="input">
                    <label>Annual Inflation Rate</label>
                    <div className="input-box">
                        <input
                            value={form.annualInflationRate}
                            name="annualInflationRate"
                            onChange={handleChange}
                        ></input>
                        <img alt="" src={percent} height="20px" />
                    </div>
                </div>
                <div className="input">
                    <label>Time Period</label>
                    <div className="input-box">
                        <input
                            value={form.timePeriod}
                            name="timePeriod"
                            onChange={handleChange}
                        ></input>
                        <span>&nbsp;Yrs.</span>
                    </div>
                </div>
                <div className="output">
                    <label>Equivalent Future Value</label>
                    <div className="output-box">
                        {output.inflatedValue != "NaN" ? (
                            <span>{output.inflatedValue}</span>
                        ) : (
                            <span>
                                <br />
                            </span>
                        )}
                        <img alt="" src={rupee}></img>
                    </div>
                </div>
            </div>
            <div className="description">
                <InflationChart
                    inflatedValue={output.inflatedValue.replace(/,/g, "")}
                    currentExpenses={form.currentExpenses.replace(/,/g, "")}
                ></InflationChart>
            </div>
        </div>
    );
};

export default InflationCalculator;
