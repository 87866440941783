import React, { Fragment } from "react";
import "../../static/css/services/lifeInsurance.css";
import backImg from "../../static/images/services vectors/life.jpg";

const LifeInsurance = () => {
  return (
    <Fragment>
      <div
        className="background"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${backImg})`,
        }}
      ></div>
      <div className="para life">
        <div className="para1">
          Life Insurance refers to a policy or cover whereby the policyholder
          can ensure financial freedom for his/her family members after death.
          Suppose you are the sole earning member in your family, supporting
          your spouse and children. In such an event, your death would
          financially devastate the whole family. Life insurance policies ensure
          that such a thing does not happen by providing financial assistance to
          your family in the event of your passing.
        </div>
        <div className="para2">
          <br></br>
          There are primarily seven different types of insurance policies when
          it comes to life insurance.
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <b>Term Plan -</b> The death benefit from a term plan is only
              available for a specified period, for instance, 40 years from the
              date of policy purchase.
            </li>
            <li>
              <b>Endowment Plan -</b> Endowment plans are life insurance
              policies where a portion of your premiums go toward the death
              benefit, while the remaining is invested by the insurance
              provider. Maturity benefits, death benefit and periodic bonuses
              are some types of assistance from endowment policies.
            </li>
            <li>
              <b>Unit Linked Insurance Plans or ULIPs -</b> Similar to endowment
              plans, a part of your insurance premiums go toward mutual fund
              investments, while the remaining goes toward the death benefit.
            </li>
            <li>
              <b>Whole Life Insurance -</b> As the name suggests, such policies
              offer life cover for the whole life of an individual, instead of a
              specified term. Some insurers may restrict the whole life
              insurance tenure to 100 years.
            </li>
            <li>
              <b>Child’s Plan -</b> Investment cum insurance policy, which
              provides financial aid for your children throughout their lives.
              The death benefit is available as a lump-sum payment after the
              death of parents.
            </li>
            <li>
              <b>Money-Back -</b> Such policies pay a certain percentage of the
              plan’s sum assured after regular intervals. This is known as
              survival benefit.
            </li>
            <li>
              <b>Retirement Plan -</b> Also known as pension plans, these
              policies are a fusion of investment and insurance. A portion of
              the premiums goes toward creating a retirement corpus for the
              policyholder. This is available as a lump-sum or monthly payment
              after the policyholder retires.
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default LifeInsurance;
