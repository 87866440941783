import React, { useEffect, useState } from "react";
import { useForm } from "../../hooks/form";

import "../../static/css/goalSip.css";
import GoalSipChart from "./goalSipChart";

import rupee from "../../static/images/rupee-indian.svg";
import percent from "../../static/images/percentage.svg";

const GoalSIP = () => {
    const [form, handleChange] = useForm({
        goalAmount: "",
        investmentDuration: "",
        expectedRateOfReturn: "",
    });

    const formChange = (e) => {
        e.target.value = e.target.value
            ? parseInt(e.target.value.replace(/,/g, "")).toLocaleString("en-IN")
            : "";
        handleChange(e);
    };

    const [result, setResult] = useState({
        totalInvestment: "",
        monthlySipAmount: "",
    });

    const computeResult = (form) => {
        const goalAmount = parseInt(form.goalAmount.replace(/,/g, ""));
        const investmentDuration = parseInt(
            form.investmentDuration.replace(/,/g, "")
        );
        const expectedRateOfReturn = parseInt(form.expectedRateOfReturn);
        return {
            totalInvestment: Math.round(
                ((goalAmount * expectedRateOfReturn) /
                    1200 /
                    (((1 + expectedRateOfReturn / 1200) **
                        (investmentDuration * 12) -
                        1) *
                        (1 + expectedRateOfReturn / 1200))) *
                    investmentDuration *
                    12
            ).toLocaleString("en-IN"),
            monthlySipAmount: Math.round(
                (goalAmount * expectedRateOfReturn) /
                    1200 /
                    (((1 + expectedRateOfReturn / 1200) **
                        (investmentDuration * 12) -
                        1) *
                        (1 + expectedRateOfReturn / 1200))
            ).toLocaleString("en-IN"),
        };
    };

    useEffect(() => {
        const result = computeResult(form);
        setResult({ ...result });
    }, [form]);
    return (
        <div className="all-cal">
            <div className="inputs">
                <div className="input input1">
                    <label>Goal Amount</label>
                    <div className="input-box">
                        <input
                            name="goalAmount"
                            value={form.goalAmount}
                            onChange={formChange}
                        ></input>
                        <img src={rupee} height="20px" alt="" />
                    </div>
                </div>
                <div className="input">
                    <label>Investment Duration</label>
                    <div className="input-box">
                        <input
                            name="investmentDuration"
                            value={form.investmentDuration}
                            onChange={handleChange}
                        ></input>
                        <span>&nbsp;Yrs.</span>
                    </div>
                </div>
                <div className="input">
                    <label>Rate of Return</label>
                    <div className="input-box">
                        <input
                            name="expectedRateOfReturn"
                            value={form.expectedRateOfReturn}
                            onChange={handleChange}
                        ></input>
                        <img src={percent} height="20px" alt="" />
                    </div>
                </div>
                <div className="output">
                    <label>Total Investment</label>
                    <div className="output-box">
                        {result.totalInvestment != "NaN" ? (
                            <span>{result.totalInvestment}</span>
                        ) : (
                            <span></span>
                        )}
                        <img src={rupee} alt="" />
                    </div>
                </div>
                <div className="output">
                    <label>Monthly SIP Amount</label>
                    <div className="output-box">
                        {result.monthlySipAmount != "NaN" ? (
                            <span>{result.monthlySipAmount}</span>
                        ) : (
                            <span></span>
                        )}
                        <img src={rupee} alt="" />
                    </div>
                </div>
            </div>
            <div className="description">
                <GoalSipChart
                    totalInvestment={result.totalInvestment.replace(/,/g, "")}
                    goalAmount={form.goalAmount.replace(/,/g, "")}
                ></GoalSipChart>
            </div>
        </div>
    );
};

export default GoalSIP;
