import React, { useState } from "react";
import "../static/css/services/common.css";

import MutualFunds from "./services/mutualFunds";
import Portfolio from "./services/portfolio";
import Insurance from "./services/insurance";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
};

const Services = () => {
    const [play, setPlay] = useState(false);

    const handleClick = () => {
        setPlay(!play);
    };

    const handleTouch = (e) => {
        setPlay(e);
    };

    return (
        <>
            <div className="play-pause">
                {play ? (
                    <span className="material-icons">play_arrow</span>
                ) : (
                    <span className="material-icons">pause</span>
                )}
            </div>
            <Carousel
                minimumTouchDrag={80}
                arrows={true}
                swipeable={true}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={true}
                autoPlay={play}
                autoPlaySpeed={2000}
                keyBoardControl={false}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={""}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
            >
                <div className="services-container">
                    <Portfolio
                        handleClick={handleClick}
                        handleTouch={handleTouch}
                    ></Portfolio>
                </div>
                <div className="services-container">
                    <MutualFunds
                        handleClick={handleClick}
                        handleTouch={handleTouch}
                    ></MutualFunds>
                </div>
                <div className="services-container">
                    <Insurance
                        handleClick={handleClick}
                        handleTouch={handleTouch}
                    ></Insurance>
                </div>
            </Carousel>
        </>
    );
};
export default Services;
