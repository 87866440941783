import React, { Fragment } from "react";
import backImg from "../../static/images/services vectors/health.jpg";

import "../../static/css/services/health.css";

const HealthInsurance = () => {
  return (
    <Fragment>
      <div
        className="background"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${backImg})`,
        }}
      ></div>
      <div className="para health">
        <div className="para1">
          Health insurance refers to a type of general insurance, which provides
          financial assistance to policyholders when they are admitted to
          hospitals for treatment. Additionally, some plans also cover the cost
          of treatment undertaken at home, prior to a hospitalisation or after
          discharge from the same. With the rising medical inflation in India,
          buying health insurance has become a necessity. However, before
          proceeding with your purchase, consider the various types of health
          insurance plans available in India.
        </div>
        <div className="para2">
          There are eight main types of health insurance policies available in
          India. They are:
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <b>Individual Health Insurance -</b> These are healthcare plans
              that offer medical cover to just one policyholder.
            </li>
            <li>
              <b>Family Floater Insurance -</b> These policies allow you to
              avail health insurance for your entire family without needing to
              buy separate plans for each member. Generally, husband, wife and
              two of their children are allowed health cover under one such
              family floater policy.
            </li>
            <li>
              <b>Critical Illness Cover -</b> These are specialised health plans
              that provide extensive financial assistance when the policyholder
              is diagnosed with specific, chronic illnesses. These plans provide
              a lump-sum payout after such a diagnosis, unlike typical health
              insurance policies.
            </li>
            <li>
              <b>Senior Citizen Health Insurance -</b> As the name suggests,
              these policies specifically cater to individuals aged 60 years and
              beyond.
            </li>
            <li>
              <b>Group Health Insurance -</b> Such policies are generally
              offered to employees of an organisation or company. They are
              designed in such a way that older beneficiaries can be removed,
              and fresh beneficiaries can be added, as per the company’s
              employee retention capability.
            </li>
            <li>
              <b>Maternity Health Insurance -</b> These policies cover medical
              expenses during pre-natal, post-natal and delivery stages. It
              covers both the mother as well as her newborn.
            </li>
            <li>
              <b>Personal Accident Insurance -</b> These medical insurance
              policies only cover financial liability from injuries, disability
              or death arising due to accidents.
            </li>
            <li>
              <b>Preventive Healthcare Plan -</b> Such policies cover the cost
              of treatment concerned with preventing a severe disease or
              condition.
            </li>
          </ul>
          <h3>Benefits of Health Insurance</h3>
          <br></br>
          After assessing the various kinds of health insurance available, you
          must be wondering why availing such a plan is essential for you and
          your loved ones. Look at the reasons listed below to understand why.
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <b>Medical Cover -</b> The primary benefit of such insurance is
              that it offers financial coverage against medical expenditure.
            </li>
            <li>
              <b>Cashless Claim -</b> If you seek treatment at one of the
              hospitals that have tie-ups with your insurance provider, you can
              avail cashless claim benefit. This feature ensures that all
              medical bills are directly settled between your insurer and
              hospital.
            </li>
            <li>
              <b>Tax Benefits -</b> Those who pay health insurance premiums can
              enjoy income tax benefits. Under Section 80D of the Income Tax Act
              one can avail a tax benefit of up to Rs.1 Lakh on the premium
              payment of their health insurance policies.
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default HealthInsurance;
