import React from "react";
import "../../static/css/services/mutualFunds.css";
import backImg from "../../static/images/services vectors/mutualFunds.jpg";

import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const MutualFunds = (props) => {
    const handleClick = props.handleClick;
    const handleTouch = props.handleTouch;
    return (
        <>
            <div className="card-container">
                <div className="card-content">
                    <div className="left">
                        <div className="card-heading">Mutual Funds</div>
                        <div className="need-help-contact-btn">
                            <Button variant="dark" className="need-help-button">
                                <Link
                                    to="/contact"
                                    style={{
                                        textDecoration: "none",
                                        color: "white",
                                    }}
                                >
                                    Start Now!
                                </Link>
                            </Button>
                        </div>
                    </div>
                    <div
                        className="right"
                        onClick={handleClick}
                        onTouchStart={() => {
                            handleTouch(false);
                        }}
                        onTouchEnd={() => {
                            handleTouch(true);
                        }}
                    >
                        <div
                            className="background"
                            style={{
                                backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)), url(${backImg})`,
                            }}
                        ></div>
                        <div className="para mutual">
                            <div className="para1">
                                Mutual funds are one of the most popular
                                investment options these days. A mutual fund is
                                an investment vehicle formed when an asset
                                management company (AMC) or fund house pools
                                investments from several individuals and
                                institutional investors with common investment
                                objectives. A fund manager, who is a finance
                                professional, manages the pooled investment. The
                                fund manager purchases securities such as stocks
                                and bonds that are in line with the investment
                                mandate.
                            </div>
                            <div className="para2">
                                Mutual funds are an excellent investment option
                                for individual investors to get exposure to an
                                expert managed portfolio. Also, you can
                                diversify your portfolio by investing in mutual
                                funds as the asset allocation would cover
                                several instruments. Investors would be
                                allocated with fund units based on the amount
                                they invest. Each investor would hence
                                experience profits or losses that are directly
                                proportional to the amount they invest. The main
                                intention of the fund manager is to provide
                                optimum returns to investors by investing in
                                securities that are in sync with the fund’s
                                objectives. The performance of mutual funds is
                                dependent on the underlying assets.
                            </div>
                            <div className="para3">
                                There are various types of funds, chief among
                                these are equity funds and debt funds. The
                                difference between the two comes from where the
                                money is invested. While debt funds invest in
                                fixed income securities, equity funds invest
                                predominantly in equity share and related
                                securities.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MutualFunds;
