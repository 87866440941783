import React from "react";
import "../static/css/calculators.css";
import { Accordion, Card } from "react-bootstrap";

import InflationCalculator from "./calculators/inflation";
import SIPcalculator from "./calculators/SIP";
import GoalSIP from "./calculators/GoalSIP";
import CAGRCal from "./calculators/CAGR";
import FutureValueCal from "./calculators/futureValueCal";
import AbsoluteReturnCal from "./calculators/absoluteResturn";

const Calculators = (props) => {
  return (
    <div className="calculators-wrapper">
      <div className="calculators-content">
        <div className="calcs-title">Calculators</div>
        <div className="calcs-accordion-wrapper">
          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <div className="calcs-accordion-toggle">
                  <div className="calcs-accordion-toggle-text">
                    <div className="calcs-accordion-title">
                      <h4>Inflation Calculator</h4>
                    </div>
                    <div className="calcs-accordion-subtitle text-muted">
                      Calculate the impact of inflation on your money. Find out
                      how much you will need in the future to meet your current
                      expenses whilst keeping up with inflation.
                    </div>
                  </div>
                  <div className="calcs-accordion-toggle-plus">
                    <span class="material-icons">add</span>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <InflationCalculator></InflationCalculator>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                <div className="calcs-accordion-toggle">
                  <div className="calcs-accordion-toggle-text">
                    <div className="calcs-accordion-title">
                      <h4>SIP Calculator</h4>
                    </div>
                    <div className="calcs-accordion-subtitle text-muted">
                      Find the future value of your monthly/quarterly SIP
                      investment.
                    </div>
                  </div>
                  <div className="calcs-accordion-toggle-plus">
                    <span class="material-icons">add</span>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <SIPcalculator></SIPcalculator>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                <div className="calcs-accordion-toggle">
                  <div className="calcs-accordion-toggle-text">
                    <div className="calcs-accordion-title">
                      <h4>Goal SIP Calculator</h4>
                    </div>
                    <div className="calcs-accordion-subtitle text-muted">
                      Determine the monthly SIP investments you need to make to
                      reach a particular goal.
                    </div>
                  </div>
                  <div className="calcs-accordion-toggle-plus">
                    <span class="material-icons">add</span>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <GoalSIP></GoalSIP>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="3">
                <div className="calcs-accordion-toggle">
                  <div className="calcs-accordion-toggle-text">
                    <div className="calcs-accordion-title">
                      <h4>Future Value Calculator</h4>
                    </div>
                    <div className="calcs-accordion-subtitle text-muted">
                      Know an estimate of the money that you can expect in the
                      future from your current investments.
                    </div>
                  </div>
                  <div className="calcs-accordion-toggle-plus">
                    <span class="material-icons">add</span>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <FutureValueCal></FutureValueCal>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="4">
                <div className="calcs-accordion-toggle">
                  <div className="calcs-accordion-toggle-text">
                    <div className="calcs-accordion-title">
                      <h4>CAGR Calculator</h4>
                    </div>
                    <div className="calcs-accordion-subtitle text-muted">
                      Calculate the compounded average rate of return you have
                      earned on your investments every year.
                    </div>
                  </div>
                  <div className="calcs-accordion-toggle-plus">
                    <span class="material-icons">add</span>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <CAGRCal></CAGRCal>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="5">
                <div className="calcs-accordion-toggle">
                  <div className="calcs-accordion-toggle-text">
                    <div className="calcs-accordion-title">
                      <h4>Absolute Return Calculator</h4>
                    </div>
                    <div className="calcs-accordion-subtitle text-muted">
                      Find what your investment has earned over the entire
                      duration of investing.
                    </div>
                  </div>
                  <div className="calcs-accordion-toggle-plus">
                    <span class="material-icons">add</span>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <AbsoluteReturnCal></AbsoluteReturnCal>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Calculators;
