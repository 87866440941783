import React from "react";
import "../../static/css/services/portfolio.css";
import backImg from "../../static/images/services vectors/portfolio.jpg";

import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const Portfolio = (props) => {
    const handleClick = props.handleClick;
    const handleTouch = props.handleTouch;
    return (
        <>
            <div className="card-container">
                <div className="card-content">
                    <div className="left">
                        <div className="card-heading">
                            <div className="heading1">Portfolio</div>
                            <div className="heading2">Management</div>
                        </div>
                        <div className="need-help-contact-btn">
                            <Button variant="dark" className="need-help-button">
                                <Link
                                    to="/contact"
                                    style={{
                                        textDecoration: "none",
                                        color: "white",
                                    }}
                                >
                                    Start Now!
                                </Link>
                            </Button>
                        </div>
                    </div>
                    <div
                        className="right"
                        onClick={handleClick}
                        onTouchStart={() => {
                            handleTouch(false);
                        }}
                        onTouchEnd={() => {
                            handleTouch(true);
                        }}
                    >
                        <div
                            className="background"
                            style={{
                                backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${backImg})`,
                            }}
                        ></div>
                        <div className="para port">
                            <div className="para1">
                                A portfolio of stocks and debts monitored and
                                professionally managed by an expert. PMS
                                promises to outperform benchmark i.e Higher
                                returns than the benchmark in the long run. You
                                get to invest across asset classes – debt,
                                equity, gold, and mutual funds. No limit to the
                                extent to which you can invest in a certain
                                stock.
                            </div>
                            <div className="para2">
                                With greater flexibility and higher
                                customisation, PMS aims to generate alpha over
                                the relevant index. While mutual funds pool
                                assets from several investors, under PMS each
                                account is managed separately and can be
                                customised to suit a client's investment
                                mandate.
                            </div>
                            <div className="para3">
                                We focus on diversification of the fund and
                                distribute across different assets allocation ,
                                our motto is let your money work for you and
                                enjoy it while growing the fund.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Portfolio;
