import React, { useEffect, useState } from "react";
import { useForm } from "../../hooks/form";

import "../../static/css/absoluteReturn.css";

import rupee from "../../static/images/rupee-indian.svg";
import percent from "../../static/images/percentage.svg";

const AbsoluteReturnCal = () => {
  const [form, handleChange] = useForm({
    initialInvestment: "",
    finalInvestment: "",
  });

  const formChange = (e) => {
    e.target.value = e.target.value
      ? parseInt(e.target.value.replace(/,/g, "")).toLocaleString("en-IN")
      : "";
    handleChange(e);
  };

  const [output, setOutput] = useState({ AbsoluteRateOfReturn: 0 });

  const computeResult = (form) => {
    const i = parseInt(form.initialInvestment.replace(/,/g, ""));
    const f = parseInt(form.finalInvestment.replace(/,/g, ""));

    console.log({ i, f });

    const AbsoluteRateOfReturn = ((f - i) / i) * 100;

    return { AbsoluteRateOfReturn: AbsoluteRateOfReturn.toFixed(2) };
  };

  useEffect(() => {
    const result = computeResult(form);
    setOutput(result);
  }, [form]);

  return (
    <div className="cagr-cal">
      <div className="inputs">
        <div className="input">
          <label>Initial Investment</label>
          <div className="input-box">
            <input
              value={form.initialInvestment}
              name="initialInvestment"
              onChange={formChange}
            ></input>
            <img src={rupee} height="20px" alt="" />
          </div>
        </div>
        <div className="input">
          <label>Final Investment</label>
          <div className="input-box">
            <input
              value={form.finalInvestment}
              name="finalInvestment"
              onChange={formChange}
            ></input>
            <img src={rupee} height="20px" alt="" />
          </div>
        </div>
        <div className="output">
          <label>Absolute Rate of Return</label>
          <div className="output-box">
            {!isNaN(output.AbsoluteRateOfReturn) ? (
              <span>{output.AbsoluteRateOfReturn}</span>
            ) : (
              <span></span>
            )}
            <img src={percent} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AbsoluteReturnCal;
