import React, { Fragment } from "react";
import backImg from "../../static/images/services vectors/motor.jpg";

import "../../static/css/services/motorInsurance.css";

const MotorInsurance = () => {
  return (
    <Fragment>
      <div
        className="background"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${backImg})`,
        }}
      ></div>
      <div className="para motor">
        <div className="para1">
          Motor insurance refers to policies that offer financial assistance in
          the event of accidents involving your car or bike. Motor insurance can
          be availed for three categories of motorised vehicles, including:
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <b>Car Insurance -</b> Personally owned four-wheeler vehicles are
              covered under such a policy.
            </li>
            <li>
              <b>Two-wheeler Insurance -</b> Personally owned two-wheeler
              vehicles, including bikes and scooters, are covered under these
              plans.
            </li>
            <li>
              <b>Commercial Vehicle Insurance -</b> If you own a vehicle that is
              used commercially, you need to avail insurance for the same.
            </li>
          </ul>
          These policies ensure that your business automobiles stay in the best
          of shapes, reducing losses significantly.
        </div>
        <div className="para2">
          Based on the extent of cover or protection offered, motor insurance
          policies are of three types, namely:
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <b>Third-Party Liability -</b> This is the most basic type of
              motor insurance cover in India. It is the minimum mandatory
              requirement for all motorised vehicle owners, as per the Motor
              Vehicles Act of 1988. Due to the limited financial assistance,
              premiums for such policies also tend to be low. These insurance
              plans only pay the financial liability to the third-party affected
              in the said mishap, ensuring that you do not face legal hassle due
              to the accident. They, however, do not offer any financial
              assistance to repair the policyholder’s vehicle after accidents.
            </li>
            <li>
              <b>Comprehensive Cover -</b> Compared to the third-party liability
              option, comprehensive insurance plans offer better protection and
              security. Apart from covering third party liabilities, these plans
              also cover the expenses incurred for repairing the damages to the
              policyholder’s own vehicle due to an accident. Additionally,
              comprehensive plans also offer a payout in case your vehicle
              sustains damage due to fire, man-made and natural calamities,
              riots and others such instances. Lastly, you can recover your
              bike’s cost if it gets stolen, when you have a comprehensive cover
              in place. One can also opt for several add-ons with their
              comprehensive motor insurance policy that can make it
              better-rounded. Some of these add-ons include zero depreciation
              cover, engine and gear-box protection cover, consumable cover,
              breakdown assistance, etc.
            </li>
            <li>
              <b>Own Damage Cover -</b> This is a specialised form of motor
              insurance, which insurance companies offer to consumers. Further,
              you are eligible to avail such a plan only if you purchased the
              two-wheeler or car after September 2018. The vehicle must be brand
              new and not a second-hand one. You should also remember that you
              can avail this standalone own damage cover only if you already
              have a third party liability motor insurance policy in place. With
              own damage cover, you basically receive the same benefits as a
              comprehensive policy without the third-party liability portion of
              the policy.
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default MotorInsurance;
