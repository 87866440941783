import React from "react";
import { Bar } from "react-chartjs-2";

import "../../static/css/inflationChart.css";

const InflationChart = (props) => {
  const inflatedValue = props.inflatedValue;
  const currentExpenses = props.currentExpenses;

  const data = {
    labels: ["Current Expenses", "Inflated Value"],
    datasets: [
      {
        data: [currentExpenses, inflatedValue],
        backgroundColor: ["#ffc93c", "#1a508b"],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: 16,
            fontColor: "#000",
          },
        },
      ],
      xAxes: [
        {
          maxBarThickness: 100,
          ticks: {
            fontSize: 16,
            fontColor: "#000",
          },
        },
      ],
    },
  };

  return (
    <>
      <div className="ichart-container">
        <Bar data={data} options={options}></Bar>
      </div>
    </>
  );
};

export default InflationChart;
