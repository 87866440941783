import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";

import Navbar from "./components/navbar";
import Home from "./components/homs";
import Services from "./components/services";
import Calculators from "./components/calculators";
import About from "./components/about";
import Contact from "./components/contact";

import Privacy from "./components/privacy";
import TermsOfUse from "./components/termsOfUse";

const NavigationbarWithRouter = withRouter(Navbar);

class App extends Component {
  render() {
    return (
      <div>
        <NavigationbarWithRouter />
        <div className="content">
          <Switch>
            <Route path="/contact" component={Contact}></Route>
            <Route path="/about" component={About}></Route>
            <Route path="/calculators" component={Calculators}></Route>
            <Route path="/services" component={Services}></Route>
            <Route path="/privacy" component={Privacy}></Route>
            <Route path="/termsofuse" component={TermsOfUse}></Route>
            <Route path="/" component={Home}></Route>
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
